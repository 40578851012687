import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism'

import ShockedJatin from "../images/shocked_jatin.svg"
import Seo from '../components/seo'
import Layout from '../components/layout'

function BlogPostTemplate(props) {
  const article = get(props, 'data.contentfulBlog')

  return (
    <Layout location={props.location}>
      <Seo
        title={article.title}
        description={article.tagline?.tagline}
      />
      <div className='article-page-header'>
        <h1 className='article-page-header-title'>{article.title}</h1>
        <h3 className='article-page-header-tagline'>{article.tagline?.tagline}</h3>
        <div className='article-page-header-info-bar'>
          <span>Published {article.publishDate}</span>
          <span>in {article.topic}</span>
          <span>{article.readTime} min read</span>
        </div>
      </div>
      <hr />
      <div className='article-writeup'>
        {article.body?.body && <ReactMarkdown children={article.body?.body} remarkPlugins={[remarkGfm]} components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, '')}
                style={atomDark}
                language={match[1]}
                PreTag="div"
                {...props}
                codeTagProps={{ className: "custom-code-snippet" }}
              />
            ) : (
              <code className=" inline-code-snippet" {...props} >
                {children}
              </code>
            )
          },
          blockquote({ children }) {
            return (
              <div className='callout-item'>
                <img src={ShockedJatin} alt="shocked jatin" className='callout-item-img' />
                <blockquote>{children}</blockquote>
              </div>
            )
          },
          h1({ children }) {
            return (
              <h1 id={get_header_id(children)}>{children}</h1>
            )
          },
          h2({ children }) {
            return (
              <h2 id={get_header_id(children)}>{children}</h2>
            )
          },
          h3({ children }) {
            return (
              <h3 id={get_header_id(children)}>{children}</h3>
            )
          }
        }} />}
      </div>
    </Layout>
  )

}

function get_header_id(data) {
  let formatted_data = data.map((item) => {
    if (typeof item === 'object') {
      item = item.props.children.join(" ")
    }
    return item
  });
  let headerString = formatted_data.join(" ")
  let id_string = headerString.replace(/\s+/g, '-').toLowerCase()
  console.log(id_string)
  return id_string
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogBySlug(
    $slug: String!
  ) {
    contentfulBlog(slug: { eq: $slug }) {
      slug
      title
      publishDate(formatString: "MMMM D, YYYY")
      readTime
      topic
      tagline {
        tagline
      }
      body {
        body
      }
    }

  }
`

